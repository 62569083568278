import React, { useState } from 'react'
import { arrayOf, bool, shape, string, func } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next';

import HukkaSelect from '../hukkaSelect'
import { get, post, FOODS_URL } from '../../utility/api'
import {
  updateOrgFoods
} from '../../actions'
import ErrorMessage from '../errorMessage'

const PLATE_WASTE_TYPE_ID = process.env.REACT_APP_PLATE_WASTE_TYPE_ID
const LINE_WASTE_TYPE_ID = process.env.REACT_APP_LINE_WASTE_TYPE_ID

const NewMealServingLogForm = ({
  wasteTypes,
  foods,
  updateFormAttribute,
  selectedWasteType,
  handleSubmit,
  selectedFood,
  preparedAmountKgs,
  wastedAmountKgs,
  edit,
  courses,
  selectedCourse,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const {
    foodCourseDefaults
  } = useSelector(state => state)

  const wasteTypeOptions = wasteTypes.map(wt => ({
    value: wt.id,
    label: t(wt.name === 'line' ? 'Linjastohävikki' : wt.name === 'plate' ? 'Lautashävikki' : wt.name === 'other' ? 'Muu hävikki' : wt.name),
  }))

  const foodOptions = foods.slice()
  .filter(f => f.name !== null && (Object.keys(f.canon).length === 0 || f.id === f.canon.food))
  .sort((a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
    return 0
  }).map(f => ({
    value: f.id,
    label: f.name,
  }))

  const courseOptions = courses.map(c => ({
    value: c.id,
    label: t(c.name),
  }))

  const handleFormSubmit = e => {
    e.preventDefault()
    handleSubmit()
  }

  const handleOptionCreate = async name => {
    setLoading(true)
    const resp = await get(FOODS_URL + '?search=' + name)

    if (resp.status === 200) {
      let find = resp.data.find(f => f.name === name)

      if (find) {
        const { id } = find
        dispatch(updateOrgFoods(find))
        updateFormAttribute('selectedFood', id)
        setLoading(false)
      } else {
        const resp2 = await post(FOODS_URL, {name})      
        if (resp2.status === 201) {
          const { id } = resp2.data
          dispatch(updateOrgFoods(resp2.data))
          updateFormAttribute('selectedFood', id)
          setLoading(false)
        } else if (resp && resp.data && resp.data.detail) {
          setLoading(false)
          setError(resp2.data.detail)
        } else {
          setLoading(false)
          setError(t('tapahtui virhe'))
        }
      }
    } else {
      const resp2 = await post(FOODS_URL, name)      
      if (resp2.status === 201) {
        const { id } = resp2.data
        dispatch(updateOrgFoods(resp2.data))
        updateFormAttribute('selectedFood', id)
        setLoading(false)
      } else if (resp && resp.data && resp.data.detail) {
        setLoading(false)
        setError(resp2.data.detail)
      } else {
        setLoading(false)
        setError(t('tapahtui virhe'))
      }
    }
  }

  const validateNumeric = (str) => {
    let orig = str.toString()
    if (orig.length === 0 || orig === '' || orig === null) {
      return '0'
    }
    let firstChar = orig.substr(0, 1)
    let lastChar = orig.substr(orig.length - 1)
    if (lastChar === ',') {
      orig += '.'
    }
    let validated = orig.replace(/[^.\d]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2")
    if (firstChar === '0') {
      if (validated.substr(1, 1) !== '.') {
        validated = validated.substr(1)
      }
    }
    return validated.toString()
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="field">
        <div className="control">
          <div className="select is-large is-fullwidth hukkaselect">
            <HukkaSelect
              options={wasteTypeOptions}
              placeholder={t('Valitse hävikkityyppi')}
              value={wasteTypeOptions.find(
                wt => wt.value === selectedWasteType
              )}
              onChange={e => updateFormAttribute('selectedWasteType', e.value)}
            />
          </div>
        </div>
      </div>

      {selectedWasteType && (
        <div>
          {selectedWasteType !== PLATE_WASTE_TYPE_ID && selectedWasteType !== wasteTypes.find(wt => wt.name === 'Keittiöhävikki').id && (
            <>
              <div className="field">
                <div className="control">
                  <div className="select is-large is-fullwidth hukkaselect">
                    <HukkaSelect
                      options={foodOptions}
                      value={foodOptions.find(f => f.value === selectedFood)}
                      placeholder={t('Valitse ruokalaji')}
                      onChange={e => {
                        updateFormAttribute('selectedFood', e.value)
                        let food = foodCourseDefaults.find(fcd => fcd.id === e.value)
                        if (!food || typeof food === 'undefined') {
                          food = foods.find(of => of.id === e.value)
                        }
                        if (food.course && food.course.id) {
                          updateFormAttribute('selectedCourse', food.course.id)
                        }
                      }}
                      formatCreateLabel={input => t('Luo uusi ruoka')+': '+input}
                      onCreateOption={label => handleOptionCreate(label)}
                      isloading={loading}
                    />
                  </div>
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <div className="select is-large is-fullwidth hukkaselect">
                    <HukkaSelect
                      options={courseOptions}
                      value={courseOptions.find(
                        f => f.value === selectedCourse
                      )}
                      placeholder={t('Valitse aterian osa')}
                      onChange={e =>
                        updateFormAttribute('selectedCourse', e.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          <ErrorMessage msg={error} />

          <div className="columns">
            {selectedWasteType === LINE_WASTE_TYPE_ID && (
              <div className="column">
                <div style={{ textAlign: 'left' }}>{t('Tarjoilumäärä')} kg</div>
                <div
                  className="field"
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                  }}
                >
                  <input
                    className="is-large has-text-primary is-expanded"
                    placeholder="Syötä tarjoilumäärä kiloina."
                    style={{ fontSize: '16px', flex: 1 }}
                    type="text"
                    disabled={selectedWasteType !== PLATE_WASTE_TYPE_ID && (!selectedCourse || !selectedFood || !selectedWasteType)}
                    inputMode="numeric"
                    value={
                      preparedAmountKgs ? preparedAmountKgs.toString() : 0
                    }
                    onChange={e => {
                      let val = e.target.value
                      if (parseFloat(val) >= 1000) {
                        val = 1000
                      }
                      updateFormAttribute(
                        'preparedAmountKg',
                        validateNumeric(val)
                      )
                    }}
                  />
                  <p className="control">
                    <span className="button is-large is-static">Kg</span>
                  </p>
                </div>
              </div>
            )}

            <div className="column">
              <div style={{ textAlign: 'left' }}><Trans>Hävikkimäärä</Trans> kg</div>
              <div
                className="field"
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                }}
              >
                <input
                  className="is-large has-text-primary"
                  placeholder={t('Syötä hävikin määrä kiloina')}
                  style={{ fontSize: '16px', flex: 1 }}
                  type="text"
                  inputMode="numeric"
                  disabled={selectedWasteType !== PLATE_WASTE_TYPE_ID && selectedWasteType !== wasteTypes.find(wt => wt.name === 'Keittiöhävikki').id && (!selectedCourse || !selectedFood || !selectedWasteType)}
                  value={(wastedAmountKgs && wastedAmountKgs.toString()) || 0}
                  onChange={e => {
                    // Dont let user to pick a number greater than preparedAmountKgs
                    let newValue = e.target.value
                    if (selectedWasteType !== PLATE_WASTE_TYPE_ID) {
                      if (parseFloat(e.target.value, 10) > parseFloat(preparedAmountKgs, 10)) {
                        newValue = parseFloat(preparedAmountKgs, 10).toString()
                      }
                    }  
                    updateFormAttribute('wasteKg', validateNumeric(newValue))
                  }}
                />
                <p className="control">
                  <span className="button is-large is-static">Kg</span>
                </p>
              </div>
            </div>
          </div>

          {!edit && (
            <div style={{ marginBottom: '35px' }}>
              <button
                type="submit"
                className="button is-large is-fullwidth is-primary"
                disabled={selectedWasteType === LINE_WASTE_TYPE_ID && !preparedAmountKgs}
              >
                <Trans>Kirjaa hävikkirivi</Trans>
              </button>
            </div>
          )}
        </div>
      )}
    </form>
  )
}

NewMealServingLogForm.defaultProps = {
  selectedWasteType: null,
  selectedFood: null,
  selectedCourse: null,
  edit: null,
  preparedAmountKgs: null,
  wastedAmountKgs: null,
}

NewMealServingLogForm.propTypes = {
  wasteTypes: arrayOf(
    shape({
      id: string,
      name: string,
    })
  ).isRequired,
  foods: arrayOf(
    shape({
      id: string,
      name: string,
    })
  ).isRequired,
  updateFormAttribute: func.isRequired,
  selectedWasteType: string,
  selectedFood: string,
  selectedCourse: string,
  handleSubmit: func.isRequired,
  preparedAmountKgs: string,
  wastedAmountKgs: string,
  edit: bool,
  courses: arrayOf(shape({ id: string, name: string })).isRequired,
}

export default NewMealServingLogForm
