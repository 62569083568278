import React, { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { post, get, WEEKSERVINGSTATS_URL, YEARSERVINGSTATS_URL, FOODSTATS_URL, MEALSERVINGS_URL, MEALSERVINGLOGS_URL } from '../../utility/api'
import {
  renewMealServings,
  renewMealServingLogs,
  setWeekServingStats,
  setYearServingStats,
  setFoodStats
} from '../../actions'
import moment from 'moment'
import HukkaSelect from '../hukkaSelect'
import ErrorMessage from '../errorMessage'
import Overview from './overview'
import Yearview from './yearview'
import Foodview from './foodview'

const COLORS = [
  '#a6cee3',
  '#1f78b4',
  '#b2df8a',
  '#33a02c',
  '#fb9a99',
  '#e31a1c',
  '#fdbf6f',
  '#ff7f00',
  '#cab2d6',
  '#6a3d9a',
  '#b15928',
  '#8dd3c7',
  '#ffffb3',
  '#bebada',
  '#fb8072',
  '#80b1d3',
  '#fdb462',
  '#b3de69',
  '#fccde5',
  '#d9d9d9',
  '#bc80bd',
  '#ccebc5',
  '#ffed6f',
  '#b3e2cd',
  '#fdcdac',
  '#cbd5e8',
  '#f4cae4',
  '#e6f5c9',
  '#fff2ae',
  '#f1e2cc',
  '#cccccc',
  '#66c2a5',
  '#fc8d62',
  '#8da0cb',
  '#e78ac3',
  '#a6d854',
  '#ffd92f',
  '#e5c494',
  '#b3b3b3',
  '#7fc97f',
  '#beaed4',
  '#fdc086',
  '#386cb0',
  '#f0027f',
  '#bf5b17',
  '#1b9e77',
  '#d95f02',
  '#7570b3',
  '#e7298a',
  '#66a61e',
  '#e6ab02',
  '#a6761d',
  '#666666',
]

const Reports = () => {
  const { t } = useTranslation()
  const [combinedValues, setCombinedValues] = useState(false)
  const [selectedYear, setSelectedYear] = useState(moment().format('YYYY'))
  const [selectedYearFrom, setSelectedYearFrom] = useState(moment().format('YYYY'))
  const [selectedYearTo, setSelectedYearTo] = useState(moment().format('YYYY'))
  const [selectedMonthFrom, setSelectedMonthFrom] = useState({label: moment().format('MMMM'), value: parseInt(moment().format('MM') - 1)})
  const [selectedMonthTo, setSelectedMonthTo] = useState({label: moment().format('MMMM'), value: parseInt(moment().format('MM') - 1)})
  const [selectedWeek, setSelectedWeek] = useState({label: t('Viikko') + ' ' + moment().isoWeek(), value: moment().isoWeek()})
  const [view, setView] = useState('overview')
  const [loading, setLoading] = useState(false)
  const [init, setInit] = useState(false)
  const [selectedRestaurants, setSelectedRestaurants] = useState([])
  const dispatch = useDispatch()
  const {
    hukkaProfiles,
    hukkaServices,
    hukkaServiceSubscriptions,
    restaurants,
    mealServings,
    mealServingLogs,
    orgs,
    wasteTypes,
    mealTypes,
    courses,
    hukkaTags
  } = useSelector(state => state)
  const { id, loggedIn, rou:readOnlyUser } = useSelector(state => state.user) || { loggedIn: false, readOnlyUser: false }
  const [errors, setErrors] = useState(null)
  const [foodViewError, setFoodViewError] = useState('')
  const [selectedWasteTypes, setSelectedWasteTypes] = useState([])
  const [selectedMealTypes, setSelectedMealTypes] = useState([])
  const [selectedCourses, setSelectedCourses] = useState([])
  const [selectedTags, setSelectedTags] = useState([])

  const wasteTarget = orgs && orgs[0].wasteTargetPercentage ? orgs[0].wasteTargetPercentage : 0
  const wasteAlarm = orgs && orgs[0].wasteAlarmPercentage ? orgs[0].wasteAlarmPercentage : 100

  const PLATE_WASTE_TYPE_ID = process.env.REACT_APP_PLATE_WASTE_TYPE_ID
  const LINE_WASTE_TYPE_ID = process.env.REACT_APP_LINE_WASTE_TYPE_ID

  // Allows us to use this function in an useEffect -function without triggering re-render on every update
  const restaurantOptions = useCallback(
    () =>
      restaurants && restaurants.slice()
      .sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
        return 0
      })
      .map(r => ({
        value: r.id,
        label: r.name,
      }))
      .concat([{ value: 'allWaste', label: t('Lisää kaikki ravintolat') }]),
    [restaurants, t]
  )

  const removeDublicatesAndNonExist = (arr) => {
    let uniqueIds = []
    let uniqueExist = []
    arr.forEach(function (a) {
      if (uniqueIds.indexOf(a.value) === -1) {
        uniqueIds.push(a.value)
        if (restaurants.find(r => r.id === a.value)) {
          uniqueExist.push(a)
        }
      }
    })
    return uniqueExist
  }

  const wasteTypeOptions = wasteTypes ? 
    wasteTypes.map(r => ({
      value: r.id,
      label: t(r.name),
    })) : []

  const mealTypeOptions = mealTypes ?
    mealTypes.map(r => ({
      value: r.id,
      label: t(r.name),
    })) : []

  const courseOptions = courses ?
    courses.map(r => ({
      value: r.id,
      label: t(r.name),
    })) : []

  const tagOptions = hukkaTags ?
    hukkaTags.filter(t => t.contentType === 13).map(r => ({
      value: r.id,
      label: r.name
    })) : []

  const restaurantGroupOptions = hukkaProfiles && hukkaProfiles.find(hp => hp.id === id).data &&
  hukkaProfiles.find(hp => hp.id === id).data.restaurantGroups
    .map(rg => ({
      value: rg.restaurants,
      label: rg.name,
    }))

  const restaurantGroupSelect = (<>
    <div className="select is-fullwidth hukkaselect">
      <HukkaSelect
        options={restaurantGroupOptions ? restaurantGroupOptions : []}
        value={''}
        placeholder={t('Valitse ravintolaryhmä')}
        onChange={e => {
          let selected = []
          if (selectedRestaurants && selectedRestaurants.length > 0) {
            selected = removeDublicatesAndNonExist(selectedRestaurants.concat(e.value))
          } else {
            selected = e.value
          }
          setSelectedRestaurants(selected)
          if (selected && selected.length > 0) {
            if (view === 'overview') {
              setLoading(true)
              getWeekServingStats(null, null, selected)
            } else if (view === 'week') {
              setLoading(true)
              dispatch(renewMealServings(null))
              dispatch(renewMealServingLogs(null))
              let monday = moment().year(selectedYear).isoWeek(selectedWeek.value).startOf('isoweek')
              let from = monday.format('YYYY-MM-DD')
              let to = monday.clone().add(7, 'days').format('YYYY-MM-DD')
              getMealServings(from, to, selected)
              getMealServingLogs(from, to, selected)
            } else if (view === 'year') {
              setLoading(true)
              let date = moment().set('year', selectedYear).startOf('year').format('YYYY-MM-DD')
              getYearServingStats(date, selected)
            }
          }
        }}
      />
    </div>
    <i style={{textAlign: 'left', display: 'block', margin: '-10px 0 10px 0'}}>{t('Lisää/Muokkaa ryhmiä')} <a href="/profile">{t('profiilisivulla')}</a></i>
  </>)

  const getMealServings = async (from, to, res) => {
    let payload = {
      from: from,
      to: to
    }
    const resp = await get(MEALSERVINGS_URL, payload)

    if (resp.status === 200) {
      dispatch(renewMealServings(resp.data))
    } else {
      setErrors(resp.data)
    }
  }
  const getMealServingLogs = async (from, to, res) => {
    setErrors(null)
    let payload = {
      from: from,
      to: to
    }
    const resp = await get(MEALSERVINGLOGS_URL, payload)

    if (resp.status === 200) {
      setErrors(null)
      dispatch(renewMealServingLogs(resp.data))
    } else {
      setErrors(resp.data)
    }
    setLoading(false)
  }
  const getWeekServingStats = async (timespan, year, res) => {
    setErrors(null)
    let currentSelectedRestaurants = res ? res : selectedRestaurants
    let restaurantArr = currentSelectedRestaurants.map(r => {
      return {
        id: r.value
      }
    })
    let yearSelection = year ? year : selectedYear
    let from = moment().set('year', yearSelection).startOf('year').format('YYYY-MM-DD')
    let to = moment().set('year', yearSelection).endOf('year').format('YYYY-MM-DD')

    let payload = {
      from_date: from,
      to_date: to,
      restaurants: restaurantArr
    }
    const resp = await post(WEEKSERVINGSTATS_URL, payload)

    if (resp.status === 200) {
      setErrors(null)
      dispatch(setWeekServingStats(resp.data))
    } else {
      setErrors(resp.data)
    }
    setLoading(false)
  }
  const getYearServingStats = async (date, res) => {
    setErrors(null)
    let currentSelectedRestaurants = res ? res : selectedRestaurants
    let restaurantArr = currentSelectedRestaurants.map(r => {
      return {
        id: r.value
      }
    })
    let payload = {
      date: date,
      restaurants: restaurantArr
    }
    const resp = await post(YEARSERVINGSTATS_URL, payload)

    if (resp.status === 200) {
      setErrors(null)
      dispatch(setYearServingStats(resp.data))
    } else {
      setErrors(resp.data)
    }
    setLoading(false)
  }
  const getFoodStats = async (from, to, res) => {
    setErrors(null)
    let currentSelectedRestaurants = res ? res : selectedRestaurants
    let restaurantArr = currentSelectedRestaurants.map(r => {
      return {
        id: r.value
      }
    })
    let payload = {
      from_datetime: from + 'T00:01',
      to_datetime: to + 'T23:59',
      restaurants: restaurantArr
    }
    const resp = await post(FOODSTATS_URL, payload)

    if (resp.status === 200) {
      setErrors(null)
      dispatch(setFoodStats(resp.data))
    } else {
      setErrors(resp.data)
    }
    setLoading(false)
  }

  if (!init) {
    setInit(true)
  }

  const monthsInYear = () => {
    let months = []
    for(let i = 0;i < 12;i++) {
      months.push({
        value: i,
        label: moment().month(i).format('MMMM')
      })
    }
    return months
  }
  
  const weeksInYear = (year) => {
    let weeksInYear = moment().year(year).isoWeeksInYear()
    let weeks = []
    for(let i = 0;i < weeksInYear;i++) {
      weeks.push({
        value: (i + 1),
        label: t('Viikko') + ' ' + (i + 1)
      })
    }
    return weeks
  }

  const daysInWeek = (year, week) => {
    let monday = moment().year(year).isoWeek(week).startOf('isoweek')
    let days = []
    for(let i = 0;i < 7;i++) {
      days.push(moment(monday.clone().add(i, 'days')).format('DD.MM.YYYY'))
    }
    return days
  }

  const weekView = () => {
    if (mealServings && mealServingLogs) {
      let html = []
      let restaurantsArr = selectedRestaurants.map(r => r.value)
      let servingDays = daysInWeek(selectedYear, selectedWeek.value)
      let servingDaysHtml = {}
      let combinedServings = {}
      servingDays.forEach(function (day) {
        servingDaysHtml[day] = []
      })

      mealServings.filter(ms => restaurantsArr.indexOf(ms.restaurant) !== -1).forEach(function (ms) {
        if (servingDays.indexOf(moment(ms.servingDatetime).format('DD.MM.YYYY')) !== -1) {

          if (combinedValues) {
            if (Object.keys(combinedServings).indexOf(moment(ms.servingDatetime).format('DD.MM.YYYY')) === -1) {
              combinedServings[moment(ms.servingDatetime).format('DD.MM.YYYY')] = {
                servingDatetime: ms.servingDatetime,
                foods: {},
                platewaste: {
                  waste: 0,
                  customers: 0
                },
                otherwaste: {},
                customers: 0
              }
            }
          }
          let servings = mealServingLogs.filter(msl => msl.mealServing === ms.id)
          let servingsHtml = []
          let platewaste = 0
          let otherwaste = {}
          let totals = {
            prepared: 0,
            wasted: 0,
            customers: ms.customers
          }
          servings.forEach(function (s) {
            if (s.wasteType === PLATE_WASTE_TYPE_ID) {
              if (combinedValues) {
                combinedServings[moment(ms.servingDatetime).format('DD.MM.YYYY')].platewaste.waste = combinedServings[moment(ms.servingDatetime).format('DD.MM.YYYY')].platewaste.waste + s.wasteGrams
                combinedServings[moment(ms.servingDatetime).format('DD.MM.YYYY')].platewaste.customers = combinedServings[moment(ms.servingDatetime).format('DD.MM.YYYY')].platewaste.customers + ms.customers
              } else {
                platewaste = platewaste + s.wasteGrams
              }
            } else if (s.wasteType === LINE_WASTE_TYPE_ID && s.foodName && s.foodName !== '') {
              if (combinedValues) {
                if (Object.keys(combinedServings[moment(ms.servingDatetime).format('DD.MM.YYYY')].foods).indexOf(s.foodName) !== -1) {
                  combinedServings[moment(ms.servingDatetime).format('DD.MM.YYYY')].foods[s.foodName]['prepared'] = combinedServings[moment(ms.servingDatetime).format('DD.MM.YYYY')].foods[s.foodName]['prepared'] + s.preparedAmountGrams
                  combinedServings[moment(ms.servingDatetime).format('DD.MM.YYYY')].foods[s.foodName]['wasted'] = combinedServings[moment(ms.servingDatetime).format('DD.MM.YYYY')].foods[s.foodName]['wasted'] + s.wasteGrams
                  combinedServings[moment(ms.servingDatetime).format('DD.MM.YYYY')].foods[s.foodName]['customers'] = combinedServings[moment(ms.servingDatetime).format('DD.MM.YYYY')].foods[s.foodName]['customers'] + ms.customers
                } else {
                  combinedServings[moment(ms.servingDatetime).format('DD.MM.YYYY')].foods[s.foodName] = {
                    prepared: s.preparedAmountGrams ? s.preparedAmountGrams : 0,
                    wasted: s.wasteGrams ? s.wasteGrams : 0,
                    customers: ms.customers
                  }
                }
              } else {
                totals.prepared = s.preparedAmountGrams + totals.prepared
                totals.wasted = s.wasteGrams + totals.wasted
                servingsHtml.push(<tr>
                  <td>{s.foodName}</td>
                  <td>{s.preparedAmountGrams ? parseFloat((s.preparedAmountGrams / 1000).toFixed(1)) : 0} kg</td>
                  <td>{s.wasteGrams ? parseFloat((s.wasteGrams / 1000).toFixed(1)) : 0} kg</td>
                  <td>{ms.customers > 0 ? parseFloat(((s.preparedAmountGrams - s.wasteGrams) / ms.customers / 1000).toFixed(3)) : 0} kg</td>
                  <td>{ms.customers > 0 ? parseFloat((s.wasteGrams / ms.customers / 1000).toFixed(3)) : 0} kg</td>
                  <td>{s.preparedAmountGrams && s.preparedAmountGrams !== 0 ? (s.wasteGrams / s.preparedAmountGrams * 100).toFixed(1) : 0} %</td>
                </tr>)
              }
            } else if (s.wasteType !== LINE_WASTE_TYPE_ID) {
              if (combinedValues) {
                if (Object.keys(combinedServings[moment(ms.servingDatetime).format('DD.MM.YYYY')].otherwaste).indexOf(s.wasteType) !== -1) {
                  combinedServings[moment(ms.servingDatetime).format('DD.MM.YYYY')].otherwaste[s.wasteType]['prepared'] = combinedServings[moment(ms.servingDatetime).format('DD.MM.YYYY')].otherwaste[s.wasteType]['prepared'] + s.preparedAmountGrams
                  combinedServings[moment(ms.servingDatetime).format('DD.MM.YYYY')].otherwaste[s.wasteType]['wasted'] = combinedServings[moment(ms.servingDatetime).format('DD.MM.YYYY')].otherwaste[s.wasteType]['wasted'] + s.wasteGrams
                  combinedServings[moment(ms.servingDatetime).format('DD.MM.YYYY')].otherwaste[s.wasteType]['customers'] = combinedServings[moment(ms.servingDatetime).format('DD.MM.YYYY')].otherwaste[s.wasteType]['customers'] + ms.customers
                } else {
                  combinedServings[moment(ms.servingDatetime).format('DD.MM.YYYY')].otherwaste[s.wasteType] = {
                    prepared: s.preparedAmountGrams ? s.preparedAmountGrams : 0,
                    wasted: s.wasteGrams ? s.wasteGrams : 0,
                    customers: ms.customers
                  }
                }
              } else {
                if (Object.keys(otherwaste).indexOf(s.wasteType) === -1) {
                  otherwaste[s.wasteType] = s.wasteGrams
                } else {
                  otherwaste[s.wasteType] = otherwaste[s.wasteType] + s.wasteGrams
                }
              }
            }
          })
          if (combinedValues) {
            combinedServings[moment(ms.servingDatetime).format('DD.MM.YYYY')]['customers'] = combinedServings[moment(ms.servingDatetime).format('DD.MM.YYYY')]['customers'] + ms.customers
          } else {
            servingDaysHtml[moment(ms.servingDatetime).format('DD.MM.YYYY')].push(<>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td><b>{restaurants.find(r => r.id === ms.restaurant).name}</b></td>
                <td><b>{moment(ms.servingDatetime).format('ddd DD.MM.')}</b></td>
                <td></td>
                <td><b>{t('Asiakasmäärä')}: {ms.customers}</b></td>
                <td></td>
                <td></td>
              </tr>
            </>)
            if (servingsHtml.length === 0) {
              servingDaysHtml[moment(ms.servingDatetime).format('DD.MM.YYYY')].push(<tr>
                <td>- {t('ei kirjattuja ruokia')} -</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>)
            } else {
              let otherwasteHtml = []
              let otherwasteTotal = 0
              if (Object.keys(otherwaste).length > 0){
                Object.keys(otherwaste).forEach(function (waste) {
                  otherwasteTotal = otherwasteTotal + otherwaste[waste]
                  otherwasteHtml.push(<tr>
                    <td><b>{t(wasteTypes.find(wt => wt.id === waste).name)}</b></td>
                    <td></td>
                    <td>{parseFloat((otherwaste[waste] / 1000).toFixed(1))} kg</td>
                    <td></td>
                    <td>{ms.customers ? parseFloat((otherwaste[waste] / 1000 / ms.customers).toFixed(3)) : 0} kg</td>
                    <td></td>
                  </tr>)
                })
              }
              servingDaysHtml[moment(ms.servingDatetime).format('DD.MM.YYYY')].push(<>
                <tr>
                  <td><b>{t('Ruoka')}</b></td>
                  <td><b>{t('Tarjoilumäärä')}</b></td>
                  <td><b>{t('Hukattu')}</b></td>
                  <td><b>{t('Menekki per asiakas')}</b></td>
                  <td><b>{t('Hävikki per asiakas')}</b></td>
                  <td><b>{t('Hävikkiprosentti')}</b></td>
                </tr>
                {servingsHtml}
                <tr style={{borderTop: '2px solid #000'}}>
                  <td><b>{t('Linjastohävikki')}</b></td>
                  <td>{parseFloat((totals.prepared / 1000).toFixed(1))} kg</td>
                  <td>{parseFloat((totals.wasted / 1000).toFixed(1))} kg</td>
                  <td>{parseFloat(((totals.prepared - totals.wasted) / totals.customers / 1000).toFixed(3))} kg</td>
                  <td>{parseFloat((totals.wasted / totals.customers / 1000).toFixed(3))} kg</td>
                  <td className={wasteTarget > (totals.wasted / totals.prepared * 100) ? 'targetValue' : (wasteAlarm < (totals.wasted / totals.prepared * 100) ? 'alarmValue' : '')}>{totals.prepared !== 0 ? parseFloat((totals.wasted / totals.prepared * 100).toFixed(1)) : 0} %</td>
                </tr>
                <tr>
                  <td><b>{t('Lautashävikki')}</b></td>
                  <td></td>
                  <td>{parseFloat((platewaste / 1000).toFixed(1))} kg</td>
                  <td></td>
                  <td>{ms.customers ? parseFloat((platewaste / 1000 / ms.customers).toFixed(3)) : 0} kg</td>
                  <td className={wasteTarget > platewaste / totals.prepared * 100 ? 'targetValue' : (wasteAlarm < platewaste / totals.prepared * 100 ? 'alarmValue' : '')}>{totals.prepared !== 0 ? parseFloat((platewaste / totals.prepared * 100).toFixed(1)) : 0} %</td>
                </tr>
                {otherwasteHtml}
                <tr style={{borderTop: '2px solid #000'}}>
                  <td><b>{t('Kaikki hävikki yhteensä')}</b></td>
                  <td>{parseFloat((totals.prepared / 1000).toFixed(1))} kg</td>
                  <td>{parseFloat(((parseFloat(totals.wasted + otherwasteTotal) + parseFloat(platewaste)) / 1000).toFixed(1))} kg</td>
                  <td>{parseFloat(((totals.prepared - (parseFloat(totals.wasted + otherwasteTotal) + parseFloat(platewaste))) / totals.customers / 1000).toFixed(3))} kg</td>
                  <td>{parseFloat(((parseFloat(totals.wasted + otherwasteTotal) + parseFloat(platewaste)) / totals.customers / 1000).toFixed(3))} kg</td>
                  <td className={wasteTarget > ((parseFloat(totals.wasted + otherwasteTotal) + parseFloat(platewaste)) / totals.prepared * 100) ? 'targetValue' : (wasteAlarm < (((parseFloat(totals.wasted + otherwasteTotal) + parseFloat(platewaste)) / totals.prepared * 100)) ? 'alarmValue' : '')}>{totals.prepared !== 0 ? parseFloat(((parseFloat(totals.wasted + otherwasteTotal) + parseFloat(platewaste)) / totals.prepared * 100).toFixed(1)) : 0} %</td>
                </tr>
              </>)
            }
          }
        }
      })

      servingDays.forEach(function (day) {
        if (html.length > 0) {
          html.push(<tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>)
        }
        html.push(<tr key={day} style={{background: '#000', color: '#fff'}}>
          <td><b>{day}</b></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>)

        if (combinedValues) {
          if (combinedServings[day]) {
            servingDaysHtml[day].push(<>
              <tr>
                <td><b></b></td>
                <td><b>{moment(combinedServings[day].servingDatetime).format('ddd DD.MM.')}</b></td>
                <td></td>
                <td><b>{t('Yhteenlaskettu asiakasmäärä')}: {combinedServings[day] ? combinedServings[day].customers : 0}</b></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td><b>{t('Ruoka')} ({t('Asiakasmäärä')})</b></td>
                <td><b>{t('Tarjoilumäärä')}</b></td>
                <td><b>{t('Hukattu')}</b></td>
                <td><b>{t('Menekki per asiakas')}</b></td>
                <td><b>{t('Hävikki per asiakas')}</b></td>
                <td><b>{t('Hävikkiprosentti')}</b></td>
              </tr>
            </>)

            let linewasteTotals = {
              prepared: 0,
              wasted: 0,
              customers: 0
            }
            let overallTotals = {
              prepared: 0,
              wasted: 0,
              customers: 0
            }

            Object.keys(combinedServings[day].foods).forEach(function (food) {
              if (combinedServings[day].foods[food].prepared > 0) {
                linewasteTotals.prepared = linewasteTotals.prepared + combinedServings[day].foods[food].prepared
                linewasteTotals.wasted = linewasteTotals.wasted + combinedServings[day].foods[food].wasted
                linewasteTotals.customers = linewasteTotals.customers + combinedServings[day].foods[food].customers

                overallTotals.prepared = overallTotals.prepared + combinedServings[day].foods[food].prepared
                overallTotals.wasted = overallTotals.wasted + combinedServings[day].foods[food].wasted
              }
              servingDaysHtml[day].push(<tr>
                <td>{food} ({combinedServings[day].foods[food].customers})</td>
                <td>{parseFloat((combinedServings[day].foods[food].prepared / 1000).toFixed(1))} kg</td>
                <td>{parseFloat((combinedServings[day].foods[food].wasted / 1000).toFixed(1))} kg</td>
                <td>{parseFloat(((combinedServings[day].foods[food].prepared - combinedServings[day].foods[food].wasted) / combinedServings[day].foods[food].customers / 1000).toFixed(3))} kg</td>
                <td>{parseFloat((combinedServings[day].foods[food].wasted / combinedServings[day].foods[food].customers / 1000).toFixed(3))} kg</td>
                <td>{parseFloat((combinedServings[day].foods[food].prepared ? (combinedServings[day].foods[food].wasted / combinedServings[day].foods[food].prepared * 100) : 0).toFixed(1))} %</td>
              </tr>)
            })

            if (combinedServings[day].platewaste.waste > 0) {
              overallTotals.wasted = overallTotals.wasted + combinedServings[day].platewaste.waste
            }
            servingDaysHtml[day].push(<>
              <tr style={{borderTop: '2px solid #000'}}>
                <td><b>{t('Linjastohävikki')} ({linewasteTotals.customers})</b></td>
                <td>{parseFloat((linewasteTotals.prepared / 1000).toFixed(1))} kg</td>
                <td>{parseFloat((linewasteTotals.wasted / 1000).toFixed(1))} kg</td>
                <td></td>
                <td></td>
                <td>{parseFloat((linewasteTotals.wasted / linewasteTotals.prepared * 100).toFixed(1))} %</td>
              </tr>
              <tr>
                <td><b>{t('Lautashävikki')} ({combinedServings[day].platewaste.customers})</b></td>
                <td></td>
                <td>{parseFloat((combinedServings[day].platewaste.waste / 1000).toFixed(1))} kg</td>
                <td></td>
                <td>{parseFloat((combinedServings[day].platewaste.waste / combinedServings[day].platewaste.customers / 1000).toFixed(3))} kg</td>
                <td>{parseFloat((combinedServings[day].platewaste.waste / linewasteTotals.prepared * 100).toFixed(1))} %</td>
              </tr>
            </>)
            if (Object.keys(combinedServings[day].otherwaste).length > 0) {
              Object.keys(combinedServings[day].otherwaste).forEach(function (waste) {
                if (combinedServings[day].otherwaste[waste].wasted > 0) {
                  overallTotals.wasted = overallTotals.wasted + combinedServings[day].otherwaste[waste].wasted
                }
                servingDaysHtml[day].push(<tr>
                  <td><b>{t(wasteTypes.find(wt => wt.id === waste).name)} ({combinedServings[day].otherwaste[waste].customers})</b></td>
                  <td></td>
                  <td>{parseFloat((combinedServings[day].otherwaste[waste].wasted / 1000).toFixed(1))} kg</td>
                  <td></td>
                  <td>{parseFloat((combinedServings[day].otherwaste[waste].wasted / combinedServings[day].otherwaste[waste].customers / 1000).toFixed(3))} kg</td>
                  <td></td>
                </tr>)
              })
            }
            servingDaysHtml[day].push(<tr style={{borderTop: '2px solid #000'}}>
                <td><b>{t('Kaikki hävikki yhteensä')}</b></td>
                <td>{parseFloat((overallTotals.prepared / 1000).toFixed(1))} kg</td>
                <td>{parseFloat((overallTotals.wasted / 1000).toFixed(1))} kg</td>
                <td></td>
                <td></td>
                <td>{parseFloat((overallTotals.wasted / overallTotals.prepared * 100).toFixed(1))} %</td>
              </tr>)
          }
        }

        servingDaysHtml[day].forEach(function (sdh) {
          html.push(sdh)
        })
      })

      return (<>
        <table className="table is-striped is-bordered" style={{margin: '20px auto'}}>
          <tbody>
            {html}
          </tbody>
        </table>
      </>)

    } else {
      return (<div></div>)
    }
  }

  const reportsView = () => {
    return (<div>
      <div>
        <h1
          className="title is-1 is-size-3-mobile is-uppercase"
          style={{margin: '0'}}
        >
          <Trans>Raportit</Trans>
        </h1>

        {errors && (
          <div style={{margin: '10px 0'}}>
            <ErrorMessage msg={errors} />
          </div>
        )}

        {restaurantGroupSelect}

        <div className="control">
          <div className="select is-fullwidth hukkaselect">
            <HukkaSelect
              options={restaurantOptions()}
              placeholder={t('Valitse ravintola')}
              value={selectedRestaurants}
              onChange={e => {
                let res = []
                if (e && e.find(r => r.value === 'allWaste')) {
                  setSelectedRestaurants(restaurantOptions().filter(ro => ro.value !== 'allWaste'))
                  res = restaurantOptions().filter(ro => ro.value !== 'allWaste')
                } else {
                  setSelectedRestaurants(e)
                  res = e
                }
                if (res && res.length > 0) {
                  if (view === 'overview') {
                    setLoading(true)
                    getWeekServingStats(null, null, res)
                  } else if (view === 'week') {
                    setLoading(true)
                    dispatch(renewMealServings(null))
                    dispatch(renewMealServingLogs(null))
                    let monday = moment().year(selectedYear).isoWeek(selectedWeek.value).startOf('isoweek')
                    let from = monday.format('YYYY-MM-DD')
                    let to = monday.clone().add(7, 'days').format('YYYY-MM-DD')
                    getMealServings(from, to, res)
                    getMealServingLogs(from, to, res)
                  } else if (view === 'year') {
                    setLoading(true)
                    let date = moment().set('year', selectedYear).startOf('year').format('YYYY-MM-DD')
                    getYearServingStats(date, res)
                  } else if (view === 'food' && (!selectedRestaurants || selectedRestaurants.length === 0)) {
                    setLoading(false)
                  }
                }
              }}
              isMulti
            />
          </div>
        </div>
      
        <div>
          <h2>{t('Datan yhdistäminen')}</h2>
          <button
            type="button"
            className={'button is-small' + (!combinedValues ? ' is-primary-background' : '')}
            style={{margin: '5px'}}
            onClick={() => {
              setCombinedValues(false)
            }}
          >
            <Trans>Ravintolat erikseen</Trans>
          </button>
          <button
            type="button"
            className={'button is-small' + (combinedValues ? ' is-primary-background' : '')}
            style={{margin: '5px'}}
            onClick={() => {
              setCombinedValues(true)
            }}
          >
            <Trans>Yhdistetty data</Trans>
          </button>
        </div>
        <br />
        <div>
          <h2>{t('Näkymä')}</h2>
          <button
            type="button"
            className={'button is-small' + (view === 'overview' ? ' is-primary-background' : '')}
            style={{margin: '5px'}}
            onClick={() => {
              if (view !== 'overview') {
                setView('overview')
                if (selectedRestaurants && selectedRestaurants.length > 0) {
                  setLoading(true)
                  getWeekServingStats()
                }
              }
            }}
          >
            <Trans>Yleiskatsaus</Trans>
          </button>
          <button
            type="button"
            className={'button is-small' + (view === 'week' ? ' is-primary-background' : '')}
            style={{margin: '5px'}}
            onClick={() => {
              if (view !== 'week') {
                dispatch(renewMealServings(null))
                dispatch(renewMealServingLogs(null))
                setView('week')
                if (selectedRestaurants && selectedRestaurants.length > 0) {
                  setLoading(true)
                  let monday = moment().year(selectedYear).isoWeek(selectedWeek.value).startOf('isoweek')
                  let from = monday.format('YYYY-MM-DD')
                  let to = monday.clone().add(7, 'days').format('YYYY-MM-DD')
                  getMealServings(from, to, selectedRestaurants)
                  getMealServingLogs(from, to, selectedRestaurants)
                }           
              }
            }}
          >
            <Trans>Viikkonäkymä</Trans>
          </button>
          <button
            type="button"
            className={'button is-small' + (view === 'year' ? ' is-primary-background' : '')}
            style={{margin: '5px'}}
            onClick={() => {
              if (view !== 'year') {
                setView('year')
                if (selectedRestaurants && selectedRestaurants.length > 0) {
                  setLoading(true)
                  let date = moment().set('year', selectedYear).startOf('year').format('YYYY-MM-DD')
                  getYearServingStats(date, selectedRestaurants)
                }
              }
            }}
          >
            <Trans>Vuosinäkymä</Trans>
          </button>
          <button
            type="button"
            className={'button is-small' + (view === 'food' ? ' is-primary-background' : '')}
            style={{margin: '5px'}}
            onClick={() => {
              if (view !== 'food') {
                setLoading(false)
                setView('food')
              }
            }}
          >
            <Trans>Ruokanäkymä</Trans>
          </button>
          <br /><br /><br />
        </div>
        
        {view === 'week' && (
          <>
            <h2>{t('Valinnat')}</h2>
            <div style={{width: '100%', maxWidth: '800px', margin: 'auto'}}>
              <span
                className="is-size-4 fas fa-chevron-left"
                style={{cursor: 'pointer'}}
                onClick={() => {
                  if (selectedRestaurants && selectedRestaurants.length > 0) {
                    dispatch(renewMealServings(null))
                    dispatch(renewMealServingLogs(null))
                    setLoading(true)
                    setSelectedYear(selectedYear - 1)
                    let monday = moment().year(selectedYear - 1).isoWeek(selectedWeek.value).startOf('isoweek')
                    let from = monday.format('YYYY-MM-DD')
                    let to = monday.clone().add(7, 'days').format('YYYY-MM-DD')
                    getMealServings(from, to, selectedRestaurants)
                    getMealServingLogs(from, to, selectedRestaurants)
                  }
                }}
              />
              <span className="is-size-4" style={{margin: '0 10px'}}>{t('Vuosi')} {selectedYear}</span>
              <span
                className="is-size-4 fas fa-chevron-right"
                style={{cursor: 'pointer', display: (selectedYear >= moment().format('YYYY') ? 'none' : 'inline-block')}}
                onClick={() => {
                  if (selectedRestaurants && selectedRestaurants.length > 0) {
                    dispatch(renewMealServings(null))
                    dispatch(renewMealServingLogs(null))
                    setLoading(true)
                    setSelectedYear(selectedYear + 1)
                    let monday = moment().year(selectedYear + 1).isoWeek(selectedWeek.value).startOf('isoweek')
                    let from = monday.format('YYYY-MM-DD')
                    let to = monday.clone().add(7, 'days').format('YYYY-MM-DD')
                    getMealServings(from, to, selectedRestaurants)
                    getMealServingLogs(from, to, selectedRestaurants)
                  }
                }}
              />
              <div>
                <HukkaSelect
                  placeholder={t('Viikko')}
                  options={weeksInYear(selectedYear)}
                  value={selectedWeek}
                  onChange={e => {
                    setSelectedWeek(e)
                    if (selectedRestaurants && selectedRestaurants.length > 0) {
                      dispatch(renewMealServings(null))
                      dispatch(renewMealServingLogs(null))
                      setLoading(true)
                      let monday = moment().year(selectedYear).isoWeek(e.value).startOf('isoweek')
                      let from = monday.format('YYYY-MM-DD')
                      let to = monday.clone().add(7, 'days').format('YYYY-MM-DD')
                      getMealServings(from, to, selectedRestaurants)
                      getMealServingLogs(from, to, selectedRestaurants)
                    }
                  }}
                />
                <button
                  type="button"
                  className={'button is-small is-secondary-background'}
                  style={{margin: '5px', color: '#fff'}}
                  onClick={() => {
                    if (selectedRestaurants && selectedRestaurants.length > 0) {
                      dispatch(renewMealServings(null))
                      dispatch(renewMealServingLogs(null))
                      setLoading(true)
                      let year = selectedYear
                      let week = selectedWeek.value
                      if (week > 1) {
                        week = week - 1
                      } else {
                        year = year - 1
                        week = weeksInYear(year).length
                        setSelectedYear(year)
                      }
                      setSelectedWeek({
                        label: 'Viikko ' + week,
                        value: week
                      })
                      let monday = moment().year(year).isoWeek(week).startOf('isoweek')
                      let from = monday.format('YYYY-MM-DD')
                      let to = monday.clone().add(7, 'days').format('YYYY-MM-DD')
                      getMealServings(from, to, selectedRestaurants)
                      getMealServingLogs(from, to, selectedRestaurants)
                    }
                  }}
                >
                  <Trans>Edellinen viikko</Trans>
                </button>
                <button
                  type="button"
                  className={'button is-small is-secondary-background'}
                  style={{margin: '5px', color: '#fff'}}
                  onClick={() => {
                    if (selectedRestaurants && selectedRestaurants.length > 0) {
                      dispatch(renewMealServings(null))
                      dispatch(renewMealServingLogs(null))
                      setLoading(true)
                      let year = selectedYear
                      let week = selectedWeek.value
                      if (week < weeksInYear(year).length) {
                        week = week + 1
                      } else {
                        year = year + 1
                        week = 1
                        setSelectedYear(year)
                      }
                      setSelectedWeek({
                        label: 'Viikko ' + week,
                        value: week
                      })
                      let monday = moment().year(year).isoWeek(week).startOf('isoweek')
                      let from = monday.format('YYYY-MM-DD')
                      let to = monday.clone().add(7, 'days').format('YYYY-MM-DD')
                      getMealServings(from, to, selectedRestaurants)
                      getMealServingLogs(from, to, selectedRestaurants)
                    }
                  }}
                >
                  <Trans>Seuraava viikko</Trans>
                </button>
              </div>
            </div>
          </>
        )}
        {view === 'year' && (
          <div>
            <h2>{t('Valinnat')}</h2>
            <span
              className="is-size-4 fas fa-chevron-left"
              style={{cursor: 'pointer'}}
              onClick={() => {
                if (selectedRestaurants && selectedRestaurants.length > 0) {
                  setLoading(true)
                  setSelectedYear(selectedYear - 1)
                  let date = moment().set('year', (selectedYear - 1)).startOf('year').format('YYYY-MM-DD')
                  getYearServingStats(date, selectedRestaurants)
                }
              }}
            />
            <span className="is-size-4" style={{margin: '0 10px'}}>{t('Vuosi')} {selectedYear}</span>
            <span
              className="is-size-4 fas fa-chevron-right"
              style={{cursor: 'pointer', display: (selectedYear >= moment().format('YYYY') ? 'none' : 'inline-block')}}
              onClick={() => {
                if (selectedRestaurants && selectedRestaurants.length > 0) {
                  setLoading(true)
                  setSelectedYear(selectedYear + 1)
                  let date = moment().set('year', (selectedYear + 1)).startOf('year').format('YYYY-MM-DD')
                  getYearServingStats(date, selectedRestaurants)
                }
              }}
            />
          </div>
        )}
        {view === 'food' && (
          <>
            <h2>{t('Aikaväli')}</h2>
            <div style={{width: '100%', maxWidth: '800px', margin: 'auto'}}>
              {foodViewError !== '' && (
                <div style={{margin: '10px 0'}}>
                  <ErrorMessage msg={foodViewError} />
                </div>
              )}
              <div style={{width: '45%', maxWidth: '300px', display: 'inline-block'}}>
                <span
                  className="is-size-4 fas fa-chevron-left"
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    setSelectedYearFrom(parseInt(selectedYearFrom) - 1)
                  }}
                />
                <span className="is-size-4" style={{margin: '0 10px'}}>{t('Vuosi')} {selectedYearFrom}</span>
                <span
                  className="is-size-4 fas fa-chevron-right"
                  style={{cursor: 'pointer', display: (selectedYearFrom >= moment().format('YYYY') ? 'none' : 'inline-block')}}
                  onClick={() => {
                    setSelectedYearFrom(parseInt(selectedYearFrom) + 1)
                  }}
                />
                <div>
                  <HukkaSelect
                    placeholder={t('Kuukausi')}
                    options={monthsInYear()}
                    value={selectedMonthFrom}
                    onChange={e => {
                      setSelectedMonthFrom(e)
                    }}
                  />
                </div>
              </div>

              <div style={{display: 'inline-block', width: '2%'}}>
                -
              </div>

              <div style={{width: '45%', maxWidth: '300px', display: 'inline-block'}}>
                <span
                  className="is-size-4 fas fa-chevron-left"
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    setSelectedYearTo(parseInt(selectedYearTo) - 1)
                  }}
                />
                <span className="is-size-4" style={{margin: '0 10px'}}>{t('Vuosi')} {selectedYearTo}</span>
                <span
                  className="is-size-4 fas fa-chevron-right"
                  style={{cursor: 'pointer', display: (selectedYearTo >= moment().format('YYYY') ? 'none' : 'inline-block')}}
                  onClick={() => {
                    setSelectedYearTo(parseInt(selectedYearTo) + 1)
                  }}
                />
                <div>
                  <HukkaSelect
                    placeholder={t('Kuukausi')}
                    options={monthsInYear()}
                    value={selectedMonthTo}
                    onChange={e => {
                      setSelectedMonthTo(e)
                    }}
                  />
                </div>
              </div>
              <br />
              <br />
              <button
                type="button"
                className={'button is-small' + (view === 'food' ? ' is-primary-background' : '')}
                style={{margin: '5px'}}
                disabled={loading}
                onClick={() => {
                  setFoodViewError('')
                  if (selectedRestaurants && selectedRestaurants.length > 0) {
                    if (selectedYearFrom > selectedYearTo) {
                      setFoodViewError(t('Alkupäivämäärä täytyy olla ennen loppupäivämäärää') + '!')
                    } else if (selectedYearFrom === selectedYearTo && parseInt(selectedMonthFrom.value) > parseInt(selectedMonthTo.value)) {
                      setFoodViewError(t('Alkupäivämäärä täytyy olla ennen loppupäivämäärää') + '!')
                    } else {
                      setLoading(true)
                      let from = moment().year(selectedYearFrom).month(selectedMonthFrom.value).startOf('month').format('YYYY-MM-DD')
                      let to = moment().year(selectedYearTo).month(selectedMonthTo.value).endOf('month').format('YYYY-MM-DD')
                      getFoodStats(from, to, selectedRestaurants)
                    }
                  } else {
                    setFoodViewError(t('Ei ravintoloita valittuna') + '!')
                  }
                }}
              >
                <Trans>Käynnistä haku</Trans>
              </button>
              <br /><br /><br />
              <h2>{t('Suodattimet')}</h2>
              <div>
                <HukkaSelect
                  placeholder={t('Kaikki aterian osat')}
                  options={courseOptions}
                  value={selectedCourses}
                  isMulti
                  onChange={e => {
                    setSelectedCourses(e)
                  }}
                />
                <br />
                <HukkaSelect
                  placeholder={t('Kaikki kattaustyypit')}
                  options={mealTypeOptions}
                  value={selectedMealTypes}
                  isMulti
                  onChange={e => {
                    setSelectedMealTypes(e)
                  }}
                />
                <br />
                <HukkaSelect
                  placeholder={t('Kaikki hävikkityypit')}
                  options={wasteTypeOptions}
                  value={selectedWasteTypes}
                  isMulti
                  onChange={e => {
                    setSelectedWasteTypes(e)
                  }}
                />
                <br />
                <HukkaSelect
                  placeholder={t('Kaikki tagit')}
                  options={tagOptions}
                  value={selectedTags}
                  isMulti
                  onChange={e => {
                    setSelectedTags(e)
                  }}
                />
              </div>
            </div>
          </>
        )}
        {view === 'overview' && (
          <>
            <div>
              <h2>{t('Valinnat')}</h2>
              <div>
                <span
                  className="is-size-4 fas fa-chevron-left"
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    if (selectedRestaurants && selectedRestaurants.length > 0) {
                      setLoading(true)
                      setSelectedYear(selectedYear - 1)
                      getWeekServingStats(null, (selectedYear - 1))
                    }
                  }}
                />
                <span className="is-size-4" style={{margin: '0 10px'}}>{t('Vuosi')} {selectedYear}</span>
                <span
                  className="is-size-4 fas fa-chevron-right"
                  style={{cursor: 'pointer', display: (selectedYear >= moment().format('YYYY') ? 'none' : 'inline-block')}}
                  onClick={() => {
                    if (selectedRestaurants && selectedRestaurants.length > 0) {
                      setLoading(true)
                      setSelectedYear(selectedYear + 1)
                      getWeekServingStats(null, (selectedYear + 1))
                    }
                  }}
                />
              </div>
            </div>
            <br />
          </>
        )}
        
        {view === 'overview' && loading === false && (
          <div>
            <Overview selectedRestaurants={selectedRestaurants} combinedValues={combinedValues} selectedYear={selectedYear} />
          </div>
        )}
        {view === 'week' && loading === false && selectedRestaurants && selectedRestaurants.length > 0 && mealServings && mealServings.length > 0 && mealServingLogs && mealServingLogs.length > 0 && (
          <div>
            {weekView()}
          </div>
        )}
        {view === 'year' && loading === false && selectedRestaurants && selectedRestaurants.length > 0 && (
          <div>
            <Yearview selectedRestaurants={selectedRestaurants} combinedValues={combinedValues} />
          </div>
        )}
        {view === 'food' && loading === false && selectedRestaurants && selectedRestaurants.length > 0 && mealServings && mealServingLogs && (
          <div>
            <Foodview restaurants={selectedRestaurants} courses={selectedCourses} mealTypes={selectedMealTypes} wasteTypes={selectedWasteTypes} tags={selectedTags} />
          </div>
        )}
        {loading === true && selectedRestaurants && selectedRestaurants.length > 0 && (
          <p className="is-size-2 is-size-3-mobile is-uppercase flashAnimation" style={{marginTop: '20px'}}>{t('Ladataan')}...</p>
        )}
      </div>
    </div>)
  }

  const checkPermission = () => {
    let reportSubId = hukkaServices.find(hs => hs.name === 'Raportointi').id
    let hasPermission = false
    for (let i = 0; i < hukkaServiceSubscriptions.length; i++) {
      if (hukkaServiceSubscriptions[i].service === reportSubId) {
        i = hukkaServiceSubscriptions.length
        hasPermission = true
      }
    }
    
    return hasPermission ? reportsView() : (<p className="is-size-2 is-size-3-mobile is-uppercase" style={{marginTop: '30px'}}>{t('Hei! Valitettavasti sinulla ei ole oikeuksia tämän palvelun käyttöön, mutta jos haluat tietää lisää, niin ota yhteyttä mailitse')}:<br /><a href="mailto:asiakaspalvelu@hukka.ai">asiakaspalvelu@hukka.ai</a></p>)
  }


  return <>
      {!hukkaProfiles && (<p className="is-size-2 is-size-3-mobile is-uppercase">{t('Ladataan')}...</p>)}
      {loggedIn && hukkaProfiles && hukkaServices && hukkaServiceSubscriptions && !readOnlyUser ? checkPermission() : ''}
    </>
}

export default Reports